<template>
  <span class="p-float-label mt-2">
    <QuillEditor
      v-model:content="text"
      :placeholder="placeholder || ''"
      content-type="html"
      theme="snow"
      class="quil-editor"
      :options="quillOptions"
    />
  </span>
</template>
<script setup lang="ts">
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { quillOptions } from "@/utils/quill";

const props = defineProps<{
  modelValue: any;
  placeholder?: string;
}>();

const emit = defineEmits(["update:modelValue"]);
const text = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss">
div.ql-tooltip.ql-editing {
  left: inherit !important;
}
</style>
